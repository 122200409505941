<template>
    <div class="custom-container">
        <cbc-searchsidebar ref="cbcsearchsidebar" :isOpen="true" :visibleSearchSideBarFooter="true">
            <template v-slot:searchSideBar>
                <div style="height: 250px;">
                    <CRow>
                        <CCol col="12">
                            <cbcinput-vue 
                                v-model="search.form.title"
                                :id="search.fields.title.id"
                                :name="search.fields.title.name"
                                :type="search.fields.title.type"
                                :ref="search.fields.title.ref"
                                :options="search.fields.title.options"
                                :disabled="search.disabled"/>
                        </CCol>
                        <CCol col="12">
                            <cbcinput-vue 
                                v-model="search.form.slug"
                                :id="search.fields.slug.id"
                                :name="search.fields.slug.name"
                                :type="search.fields.slug.type"
                                :ref="search.fields.slug.ref"
                                :options="search.fields.slug.options"
                                :disabled="search.disabled"/>
                        </CCol>
                        <CCol col="12">
                            <cbcinput-vue 
                                v-model="search.form.status"
                                :id="search.fields.status.id"
                                :name="search.fields.status.name"
                                :type="search.fields.status.type"
                                :ref="search.fields.status.ref"
                                :options="search.fields.status.options"
                                :disabled="search.disabled"/>
                        </CCol>
                    </CRow>
                </div>
            </template>
            <template v-slot:searchSideBarFooter>
                <CButton
                    block
                    color="primary"
                    :disabled="search.disabled"
                    @click="getCampaigns(false)"
                ><i class="fas fa-circle-notch fa-lg fa-spin mr-2" v-show="search.disabled" />Ara
                </CButton>
            </template>
            <template v-slot:main>
                <CCard class="rounded-0 border-0">
                    <CCardHeader class="d-flex flex-row justify-content-between align-items-center rounded-0 border-right">
                        <span class="font-weight-bold">Kampanyalar</span>
                        <div class="card-header-actions">
                            <CButton color="success" class="btn-coreui mr-2" :disabled="search.disabled" @click="openModal(modals.addUpdate, 'Kampanya Ekle', 'post')">
                                <i class="fas fa-circle-notch fa-lg fa-spin mr-2" v-show="search.disabled" />Kampanya Ekle
                            </CButton>
                            <CButton
                                class="btn-coreui" 
                                :disabled="search.disabled"
                                @click="$refs.cbcsearchsidebar.openNav()"
                            ><i class="far fa-search fa-lg text-white" />
                            </CButton>
                        </div>
                    </CCardHeader>
                    <CCardBody class="p-0">
                        <!-- :sendToClipboard="sendToClipboard" -->
                        <ag-grid-vue
                            style="width: 100%; height: 500px"
                            class="ag-theme-balham"
                            :columnDefs="columnDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="rowData"
                            :sideBar="sideBar"
                            :getContextMenuItems="getContextMenuItems"
                            :rowSelection="rowSelection"
                            :rowHeight="rowHeight"
                            :enableRangeSelection="true"
                            :statusBar="statusBar"
                            :overlayLoadingTemplate="'Yükleniyor..'"
                            :overlayNoRowsTemplate="'Kayıt bulunamadı'"
                            @grid-ready="onGridReady">
                        </ag-grid-vue>
                    </CCardBody>
                </CCard>
            </template>
        </cbc-searchsidebar>

        <CModal
            size="xl"
            :title="modals.addUpdate.title"
            :show.sync="modals.addUpdate.show"
            :closeOnBackdrop="false"
        >
            <CRow v-if="modals.addUpdate.form !== null">
                <CCol md="4">
                    <ImageUploader
                        id="imageUploader" 
                        name="imageUploader" 
                        ref="imageUploader"
                        :previewWidth="'100%'"
                        :previewHeight="'250px'"
                        :allowedMultipleSelection="true"
                        :maxImageUploadSize="5242880"
                        :maxAddImageCount="1"
                        :visibleTitle="true"
                        :axiosURL="VUE_APP_API_URL + 'admin/writetemp/file'"
                        :cdnDir="VUE_APP_CDN_URL"
                        :cdnImageDir="VUE_APP_CDN_URL_CAMPAIGNS_IMAGE_DIR"
                        :createAndUploadThumbnail="false"
                    />
                </CCol>
                <CCol md="8">
                    <CRow>
                        <CCol col="12" md="6">
                            <cbcinput-vue 
                                v-model="modals.addUpdate.form.title"
                                :id="modals.addUpdate.fields.title.id"
                                :name="modals.addUpdate.fields.title.name"
                                :type="modals.addUpdate.fields.title.type"
                                :ref="modals.addUpdate.fields.title.ref"
                                :options="modals.addUpdate.fields.title.options"
                                :disabled="modals.addUpdate.disabled"/>
                        </CCol>
                        <CCol col="12" md="6">
                            <cbcinput-vue 
                                v-model="modals.addUpdate.form.slug"
                                :id="modals.addUpdate.fields.slug.id"
                                :name="modals.addUpdate.fields.slug.name"
                                :type="modals.addUpdate.fields.slug.type"
                                :ref="modals.addUpdate.fields.slug.ref"
                                :options="modals.addUpdate.fields.slug.options"
                                :disabled="modals.addUpdate.disabled"/>
                        </CCol>
                        <CCol col="12" md="6">
                            <h6 class="font-weight-bold" style="margin-top: 2px; margin-bottom: 10px;">Kampanya Başlangıç Tarihi <span style="color: red">*</span></h6>
                            <div class="d-flex flex-row">
                                <cbcinput-vue 
                                    v-model="modals.addUpdate.form.startDateDay"
                                    :id="modals.addUpdate.fields.startDateDay.id"
                                    :name="modals.addUpdate.fields.startDateDay.name"
                                    :type="modals.addUpdate.fields.startDateDay.type"
                                    :ref="modals.addUpdate.fields.startDateDay.ref"
                                    :options="modals.addUpdate.fields.startDateDay.options"
                                    :disabled="modals.addUpdate.disabled"/>
                                <cbcinput-vue 
                                    class="ms-2"
                                    v-model="modals.addUpdate.form.startDateMonth"
                                    :id="modals.addUpdate.fields.startDateMonth.id"
                                    :name="modals.addUpdate.fields.startDateMonth.name"
                                    :type="modals.addUpdate.fields.startDateMonth.type"
                                    :ref="modals.addUpdate.fields.startDateMonth.ref"
                                    :options="modals.addUpdate.fields.startDateMonth.options"
                                    :disabled="modals.addUpdate.disabled"/>
                                <cbcinput-vue 
                                    class="ms-2"
                                    v-model="modals.addUpdate.form.startDateYear"
                                    :id="modals.addUpdate.fields.startDateYear.id"
                                    :name="modals.addUpdate.fields.startDateYear.name"
                                    :type="modals.addUpdate.fields.startDateYear.type"
                                    :ref="modals.addUpdate.fields.startDateYear.ref"
                                    :options="modals.addUpdate.fields.startDateYear.options"
                                    :disabled="modals.addUpdate.disabled"/>
                            </div>
                        </CCol>
                        <CCol col="12" md="6">
                            <h6 class="font-weight-bold" style="margin-top: 2px; margin-bottom: 10px;">Kampanya Bitiş Tarihi <span style="color: red">*</span></h6>
                            <div class="d-flex flex-row">
                                <cbcinput-vue 
                                    v-model="modals.addUpdate.form.endDateDay"
                                    :id="modals.addUpdate.fields.endDateDay.id"
                                    :name="modals.addUpdate.fields.endDateDay.name"
                                    :type="modals.addUpdate.fields.endDateDay.type"
                                    :ref="modals.addUpdate.fields.endDateDay.ref"
                                    :options="modals.addUpdate.fields.endDateDay.options"
                                    :disabled="modals.addUpdate.disabled"/>
                                <cbcinput-vue 
                                    class="ms-2"
                                    v-model="modals.addUpdate.form.endDateMonth"
                                    :id="modals.addUpdate.fields.endDateMonth.id"
                                    :name="modals.addUpdate.fields.endDateMonth.name"
                                    :type="modals.addUpdate.fields.endDateMonth.type"
                                    :ref="modals.addUpdate.fields.endDateMonth.ref"
                                    :options="modals.addUpdate.fields.endDateMonth.options"
                                    :disabled="modals.addUpdate.disabled"/>
                                <cbcinput-vue 
                                    class="ms-2"
                                    v-model="modals.addUpdate.form.endDateYear"
                                    :id="modals.addUpdate.fields.endDateYear.id"
                                    :name="modals.addUpdate.fields.endDateYear.name"
                                    :type="modals.addUpdate.fields.endDateYear.type"
                                    :ref="modals.addUpdate.fields.endDateYear.ref"
                                    :options="modals.addUpdate.fields.endDateYear.options"
                                    :disabled="modals.addUpdate.disabled"/>
                            </div>
                        </CCol>
                        <CCol col="12" md="6">
                            <cbcinput-vue 
                                v-model="modals.addUpdate.form.status"
                                :id="modals.addUpdate.fields.id"
                                :name="modals.addUpdate.fields.status.name"
                                :type="modals.addUpdate.fields.status.type"
                                :ref="modals.addUpdate.fields.status.ref"
                                :options="modals.addUpdate.fields.status.options"
                                :disabled="modals.addUpdate.disabled"/>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol class="mt-3">
                    <h6 class="font-weight-bold" style="margin-top: 2px; margin-bottom: 10px;">Açıklama <span style="color: red">*</span></h6>
                    <ckeditor v-model="modals.addUpdate.form.description.value" :config="ckeditor.config" :readOnly="modals.addUpdate.disabled"></ckeditor>
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    color="primary"
                    :disabled="modals.addUpdate.disabled"
                    @click="addUpdate()"
                ><i class="fas fa-circle-notch fa-lg fa-spin mr-2" v-show="modals.addUpdate.disabled" />{{ modals.addUpdate.process === 'post' ? 'Ekle':'Düzenle' }}
                </CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Func from '@/func';
import Swal from 'sweetalert2';
import { AgGridVue } from 'ag-grid-vue';
import ImageUploader from '@/components/ImageUploader';
import moment from 'moment';
import CKEditor from 'ckeditor4-vue';

Vue.use(CKEditor);

export default {
    name: 'Campaigns',
    components: {
        AgGridVue,
        AgGridCellSwitcher: {
            template: `
                <label class="switch">
                    <input type="checkbox" @change="chkChangedHandler($event)" v-bind="[params.data.status === true && {'checked':true}]">
                    <span class="slider round"></span>
                </label>
            `,
            methods: {
                chkChangedHandler(event) {
                    this.params.changed(this.params.data._id, event.target.checked);
                }
            }
        },
        ImageUploader
    },
    data() {
        var _this = this;

        return {
            VUE_APP_URL: process.env.VUE_APP_URL,
            VUE_APP_API_URL: process.env.VUE_APP_API_URL,
            VUE_APP_CDN_URL: process.env.VUE_APP_CDN_URL,
            VUE_APP_CDN_URL_CAMPAIGNS_IMAGE_DIR: process.env.VUE_APP_CDN_URL_CAMPAIGNS_IMAGE_DIR,
            columnDefs: [
                {
                    field: 'status',
                    cellRenderer: 'AgGridCellSwitcher',
                    cellRendererParams: {
                        _this: this,
                        changed: function(_id, checked) {
                            _this.changeStatus(this.data._id, checked);
                        }
                    },
                    cellStyle: () => { return { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                    headerName: 'Durum',
                    width: 80
                },
                {
                    field: 'image',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    cellRenderer: params => {
                        // Resim yoksa blank resim göster
                        if (params.value === '') return `<img src="${this.VUE_APP_URL + 'fileImage.png'}" style="width: 50px; height: 50px; object-fit: contain;" class="rounded" />`;
                        else return `<img src="${this.VUE_APP_CDN_URL + this.VUE_APP_CDN_URL_CAMPAIGNS_IMAGE_DIR + params.value}" style="width: 50px; height: 50px; object-fit: contain;" class="rounded" />`;
                    },
                    headerName: 'Resim',
                    cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
                    width: 100
                },
                {
                    field: 'title',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    headerName: 'Kampanya Adı',
                    cellStyle: { display: 'flex', alignItems: 'center' },
                    width: 250
                },
                {
                    field: 'slug',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    headerName: 'Kampanya Slug',
                    cellStyle: { display: 'flex', alignItems: 'center' },
                    width: 200
                },
                {
                    field: 'beginDate',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    cellRenderer: params => {
                        return moment(new Date(params.value)).locale('tr').format("DD MMMM YYYY");
                    },
                    headerName: 'Kampanya Başlangıç Tarihi',
                    cellStyle: { display: 'flex', alignItems: 'center' },
                    width: 200
                },
                {
                    field: 'expirationDate',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    cellRenderer: params => {
                        return moment(new Date(params.value)).locale('tr').format("DD MMMM YYYY");
                    },
                    headerName: 'Kampanya Bitiş Tarihi',
                    cellStyle: { display: 'flex', alignItems: 'center' },
                    width: 170
                },
                /* {
                    field: 'expirationDate',
                    onCellDoubleClicked: params => this.rowDoubleClicked(params),
                    cellRenderer: params => {
                        // Kampanya bitiş tarihi geçmişse
                        if (new Date(params.value) < new Date()) return `<span class="badge badge-danger">Süre Doldu</span>`;
                        else {
                            var remainingTime = Func.getRemainingTime(new Date(params.value), new Date());

                            if (remainingTime.hours < 1) return `<span class="badge badge-danger">${remainingTime.minutes} Dakika ${remainingTime.seconds} Saniye</span>`;
                            else if (remainingTime.days < 1) return `<span class="badge badge-warning">${remainingTime.hours} Saat ${remainingTime.minutes} Dakika</span>`;
                            else return `<span class="badge badge-success">${remainingTime.days} Gün ${remainingTime.hours} Saat ${remainingTime.minutes} Dakika</span>`;
                        }
                    },
                    headerName: 'Kalan Süre',
                    cellStyle: { display: 'flex', alignItems: 'center', fontSize: '14px' },
                    width: 170
                } */
            ],
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true,
            },
            rowData: [],
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Sütunlar',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                    },
                    ,
                    {
                        id: 'filters',
                        labelDefault: 'Filtreler',
                        labelKey: 'filters',
                        iconKey: 'filter',
                        toolPanel: 'agFiltersToolPanel',
                    },
                ],
                defaultToolPanel: '',
            },
            rowSelection: 'multiple',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left'
                    },
                    {
                        statusPanel: 'agTotalRowCountComponent',
                        align: 'center',
                    },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    { statusPanel: 'agAggregationComponent' },
                ],
            },
            rowHeight: 60,

            ckeditor: {
                config: {
                    entities_latin: false,
                    toolbarGroups: [
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                        { name: 'forms', groups: [ 'forms' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                        '/',
                        { name: 'links', groups: [ 'links' ] },
                        { name: 'insert', groups: [ 'insert' ] },
                        '/',
                        { name: 'styles', groups: [ 'styles' ] },
                        { name: 'colors', groups: [ 'colors' ] },
                        { name: 'tools', groups: [ 'tools' ] },
                        { name: 'others', groups: [ 'others' ] },
                        { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons: 'Save,Templates,NewPage,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,JustifyBlock,JustifyRight,JustifyCenter,JustifyLeft,Styles,Format,Font,FontSize,TextColor,BGColor,ShowBlocks,Maximize,About,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Anchor,Unlink,Link,BidiLtr,BidiRtl,Language,Replace,ExportPdf,Preview,Print,CopyFormatting,RemoveFormat,Superscript,Subscript,Blockquote,BulletedList,NumberedList,Outdent,Indent,Scayt,SelectAll,Find,PasteFromWord'
                }
            },
            subjectType: {
                'casco': 'Kasko',
                'traffic': 'Trafik',
                'dask': 'Dask',
                'housing': 'Konut',
                'privHealth': 'Özel Sağlık',
                'compHealth': 'Tam Sağlık',
                'Diğer': 'Diğer',
            },

            modals: {
                addUpdate: {
                    show: false,
                    title: '',
                    process: '',
                    disabled: false,
                    watchDisabled: false,
                    fields: {
                        title: {
                            id: 'title',
                            name: 'title',
                            type: 'text',
                            ref: 'title',
                            options: {
                                label: 'Kampanya Adı <span style=\'color: red\'>*</span>',
                                labelBold: true,
                                placeholder: 'Kampanya Adı girin',
                                maxlength: 150,
                                shaker: true,
                                inputIcon: {
                                    status: true,
                                    placement: 'left',
                                    icon: {
                                        library: 'fontawesome',
                                        src: ['far', 'building']
                                    }
                                },
                                validRequired: {
                                    status: true,
                                    errorMessage: 'Bu alanı boş bırakamazsınız!'
                                },
                                validLengthMin: {
                                    status: true,
                                    number: 2,
                                    errorMessage: 'Bu alana en az <strong>2</strong> karakter girebilirsiniz!'
                                }
                            }
                        },
                        /* description: {
                            id: 'description',
                            name: 'description',
                            type: 'textarea',
                            ref: 'description',
                            options: {
                                label: 'Kampanya Açıklaması <span style=\'color: red\'>*</span>',
                                labelBold: true,
                                placeholder: 'Kampanya açıklaması girin',
                                maxlength: 3000,
                                height: '150px',
                                shaker: true,
                                resize: false,
                                textCounter: true,
                                validRequired: {
                                    status: true,
                                    errorMessage: 'Bu alanı boş bırakamazsınız!'
                                },
                                validLengthMin: {
                                    status: true,
                                    number: 5,
                                    errorMessage: 'Bu alana en az <strong>5</strong> karakter girebilirsiniz!'
                                },
                                validLengthMax: {
                                    status: true,
                                    number: 3000,
                                    errorMessage: 'Bu alana en az <strong>3000</strong> karakter girebilirsiniz!'
                                }
                            }
                        }, */
                        slug: {
                            id: 'slug',
                            name: 'slug',
                            type: 'text',
                            ref: 'slug',
                            options: {
                                label: 'Kampanya Slug <span style=\'color: red\'>*</span>',
                                labelBold: true,
                                placeholder: 'Kampanya Slug girin',
                                maxlength: 50,
                                shaker: true,
                                inputIcon: {
                                    status: true,
                                    placement: 'left',
                                    icon: {
                                        library: 'fontawesome',
                                        src: ['far', 'book-user']
                                    }
                                },
                                validRequired: {
                                    status: true,
                                    errorMessage: 'Bu alanı boş bırakamazsınız!'
                                },
                                validLengthMin: {
                                    status: true,
                                    number: 2,
                                    errorMessage: 'Bu alana en az <strong>2</strong> karakter girebilirsiniz!'
                                }
                            }
                        },
                        status: {
                            id: 'status',
                            name: 'status',
                            type: 'select',
                            ref: 'status',
                            label: 'Durum',
                            options: {
                                label: 'Durum <span style=\'color: red\'>*</span>',
                                labelBold: true,
                                shaker: true,
                                selectOptions: [
                                    { value: true, label: 'Aktif' },
                                    { value: false, label: 'Pasif' }
                                ],
                                validRequired: {
                                    status: true,
                                    errorMessage: 'Bu alanı boş geçemezsiniz!'
                                }
                            }
                        },
                        startDateDay: {
                            id: 'startDateDay',
                            name: 'startDateDay',
                            type: 'select',
                            ref: 'startDateDay',
                            options: {
                                selectSettings: {
                                    placeholder: 'Gün',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                        startDateMonth: {
                            id: 'startDateMonth',
                            name: 'startDateMonth',
                            type: 'select',
                            ref: 'startDateMonth',
                            options: {
                                selectSettings: {
                                    placeholder: 'Ay',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                        startDateYear: {
                            id: 'startDateYear',
                            name: 'startDateYear',
                            type: 'select',
                            ref: 'startDateYear',
                            options: {
                                selectSettings: {
                                    placeholder: 'Yıl',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                        endDateDay: {
                            id: 'endDateDay',
                            name: 'endDateDay',
                            type: 'select',
                            ref: 'endDateDay',
                            options: {
                                selectSettings: {
                                    placeholder: 'Gün',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                        endDateMonth: {
                            id: 'endDateMonth',
                            name: 'endDateMonth',
                            type: 'select',
                            ref: 'endDateMonth',
                            options: {
                                selectSettings: {
                                    placeholder: 'Ay',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                        endDateYear: {
                            id: 'endDateYear',
                            name: 'endDateYear',
                            type: 'select',
                            ref: 'endDateYear',
                            options: {
                                selectSettings: {
                                    placeholder: 'Yıl',
                                    searchable: true
                                },
                                selectOptions: []
                            }
                        },
                    },
                    form: null
                }
            },

            search: {
                disabled: false,
                fields: {
                    title: {
                        id: 'title',
                        name: 'title',
                        type: 'text',
                        ref: 'title',
                        options: {
                            /* label: 'Ad <span style=\'color: red\'>*</span>',
                            labelBold: true, */
                            placeholder: 'Kampanya adı girin',
                            maxlength: 50,
                            inputIcon: {
                                status: true,
                                placement: 'left',
                                icon: {
                                    library: 'fontawesome',
                                    src: ['far', 'building']
                                }
                            }
                        }
                    },
                    slug: {
                        id: 'slug',
                        name: 'slug',
                        type: 'text',
                        ref: 'slug',
                        options: {
                            /* label: 'Slug <span style=\'color: red\'>*</span>',
                            labelBold: true, */
                            placeholder: 'Kampanya slug girin',
                            maxlength: 50,
                            inputIcon: {
                                status: true,
                                placement: 'left',
                                icon: {
                                    library: 'fontawesome',
                                    src: ['far', 'building']
                                }
                            }
                        }
                    },
                    status: {
                        id: 'status',
                        name: 'status',
                        type: 'select',
                        ref: 'status',
                        options: {
                            /* label: 'Durum <span style=\'color: red\'>*</span>',
                            labelBold: true, */
                            selectSettings: {
                                placeholder: 'Durum seçin',
                                showLabels: true,
                                selectedLabel: 'Seçildi',
                                deselectLabel: 'Kaldır',
                                allowEmpty: true,
                            },
                            selectOptions: [
                                { value: true, label: 'Aktif' },
                                { value: false, label: 'Pasif' }
                            ]
                        }
                    }
                },
                form: {
                    title: { value: '', valid: null },
                    slug: { value: '', valid: null },
                    status: { value: '', valid: null }
                }
            }
        };
    },
    created() {
        var Dates = Func.initSelectBoxDate(null, null, -39, 40);
        
        this.modals.addUpdate.fields.startDateDay.options.selectOptions = Dates.days;
        this.modals.addUpdate.fields.startDateMonth.options.selectOptions = Dates.months;
        this.modals.addUpdate.fields.startDateYear.options.selectOptions = Dates.years;
        this.modals.addUpdate.fields.endDateDay.options.selectOptions = Dates.days;
        this.modals.addUpdate.fields.endDateMonth.options.selectOptions = Dates.months;
        this.modals.addUpdate.fields.endDateYear.options.selectOptions = Dates.years;

        this.getCampaigns({ params: this.search.form });       
    },
    watch: {
        'modals.addUpdate.form.title.value': function(val) {
            this.modals.addUpdate.form.slug.value = Func.slugify(val);
        },
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        },
        getContextMenuItems(params) {
            const _this = this;

            return [
                {
                    name: 'Sil',
                    action: function() {
                        Swal.fire({
                            icon: 'error',
                            title: 'Kampanya Sil',
                            html: `<strong> ${params.node.data.title} </strong> isimli kampanyayı silmek istiyor musunuz?`,
                            confirmButtonText: 'Evet',
                            confirmButtonColor: '#1565C0',
                            showDenyButton: true,
                            denyButtonText: 'Hayır'
                        }).then(result => {
                            if(result.isConfirmed) _this.delete(params.node.data._id);
                        });
                    }
                },
                {
                    name: 'Düzenle',
                    action: async function() {
                        _this.openModal(_this.modals.addUpdate, 'Kampanya Düzenle', 'put', params.node.data);
                    },
                },
                {
                    name: 'Kopyala',
                    action: function() {
                        _this.gridApi.copySelectedRangeToClipboard();
                    },
                },
            ];
        },
        rowDoubleClicked(row) {
            this.openModal(this.modals.addUpdate, 'Kampanya Düzenle', 'put', row.data);
        },
        async openModal(modal, title, _process = '', data = null) {
            modal.watchDisabled = true;

            switch (modal) {
                case this.modals.addUpdate:
                    modal.title = title;
                    modal.process = _process;

                    await new Promise(resolve => {
                        if(data !== null) {
                            modal.form = {
                                _id: data._id,
                                title: { value: data.title, valid: true },
                                slug: { value: data.slug, valid: true },
                                status: { value: data.status, valid: true },
                                description: { value: data.description, valid: true },
                                startDateDay: { value: moment(data.beginDate).format('DD'), valid: true },
                                startDateMonth: { value: moment(data.beginDate).format('MM'), valid: true },
                                startDateYear: { value: moment(data.beginDate).format('YYYY'), valid: true },
                                endDateDay: { value: moment(data.expirationDate).format('DD'), valid: true },
                                endDateMonth: { value: moment(data.expirationDate).format('MM'), valid: true },
                                endDateYear: { value: moment(data.expirationDate).format('YYYY'), valid: true },
                            }

                            setTimeout(() => {
                                if(data.image) {
                                    // LOGO UPLOADER - BAŞLANGIÇ
                                    this.$refs.imageUploader.setAddedImages([{
                                        src: data.image,
                                        blob: null,
                                        type: '',
                                        fileName: '',
                                        size: 0,
                                        orjSize: 0,
                                        progress: 0,
                                        uploadFailed: false,
                                        uploadedFileNameThumb: data.image,
                                        uploadedFileName: data.image
                                    }]);
                                }
                            }, 5000);
                        } else {
                            modal.form = {
                                _id: '',
                                title: { value: '', valid: null },
                                slug: { value: '', valid: null },
                                status: { value: true, valid: true },
                                description: { value: '', valid: null },
                                startDateDay: { value: moment().format('DD'), valid: true },
                                startDateMonth: { value: moment().format('MM'), valid: true },
                                startDateYear: { value: moment().format('YYYY'), valid: true },
                                endDateDay: { value: moment().format('DD'), valid: true },
                                endDateMonth: { value: moment().add(1, 'months').format('MM'), valid: true },
                                endDateYear: { value: moment().format('YYYY'), valid: true },
                            };
                        }

                        resolve(true);
                    });
                    
                    break;
            }

            modal.watchDisabled = false;
            modal.show = true;
        },
        closeModal(modal) {
            modal.show = false;
        },
        async getCampaigns(collapse = false) {
            var form = {
                title: this.search.form.title.value,
                slug: this.search.form.slug.value,
                status: this.search.form.status.value.value
            }

            this.$Progress.start();
            this.search.disabled = true;
            var getResult = await axios.get(process.env.VUE_APP_API_URL + 'admin/campaigns', { params: form });
            this.$Progress.finish();
            this.search.disabled = false;

            if(getResult.data.result === 'success') this.rowData = getResult.data.list;
            else this.rowData = [];

            if(collapse === true) this.$refs.cbcsearchsidebar.closeNav();
        },
        async addUpdate() {
            var inputErrors = [];

            this.$refs.title.validControl(true);
            inputErrors.push(this.modals.addUpdate.form.title.valid);
            
            this.$refs.slug.validControl(true);
            inputErrors.push(this.modals.addUpdate.form.slug.valid);

            /* this.$refs.description.validControl(true);
            inputErrors.push(this.modals.addUpdate.form.description.valid); */

            // inputErrors array içinde false olan varsa
            if(inputErrors.includes(false) === true) return;

            this.$Progress.start();
            this.modals.addUpdate.disabled = true;

            // LOGO UPLOADER - BAŞLANGIÇ
            var imageUploadedImages = await this.$refs.imageUploader.uploadImages(); // Seçilen resim varsa sunucunun temp klasörüne yülüyoruz. İşlem sonunda yüklenen dosyaların datasını array döner.
            // LOGO UPLOADER - BİTİŞ

            if (imageUploadedImages && imageUploadedImages[0].uploadFailed === true) {
                this.$Progress.finish();
                this.modals.addUpdate.disabled = false;

                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    html: imageUploadedImages[0].uploadMessage,
                    confirmButtonText: 'Tamam',
                    confirmButtonColor: '#1565C0'
                });

                return;
            }

            var form = {
                title: this.modals.addUpdate.form.title.value,
                slug: this.modals.addUpdate.form.slug.value,
                status: this.modals.addUpdate.form.status.value.value,
                description: this.modals.addUpdate.form.description.value,
                beginDate: moment(this.modals.addUpdate.form.startDateYear.value.value + '-' + this.modals.addUpdate.form.startDateMonth.value.value + '-' + this.modals.addUpdate.form.startDateDay.value.value).format('YYYY-MM-DD'),
                expirationDate: moment(this.modals.addUpdate.form.endDateYear.value.value + '-' + this.modals.addUpdate.form.endDateMonth.value.value + '-' + this.modals.addUpdate.form.endDateDay.value.value).format('YYYY-MM-DD'),
                image: imageUploadedImages ? 
                    imageUploadedImages.map(e => { 
                        return {
                            fileName: e.uploadedFileName,
                            size: e.size.split('&nbsp;').join(' ')
                        }
                    })
                :
                    []
            }

            if(this.modals.addUpdate.process === 'put') form._id = this.modals.addUpdate.form._id;

            this.$Progress.start();
            this.modals.addUpdate.disabled = true;
            var addUpdateResult = await axios[this.modals.addUpdate.process](process.env.VUE_APP_API_URL + 'admin/campaigns', form);
            this.$Progress.finish();
            this.modals.addUpdate.disabled = false;

            if(addUpdateResult.data.result === 'success') {
                window.location.reload();
                /* this.getCampaigns(true);
                this.closeModal(this.modals.addUpdate);
                
                Swal.fire({
                    icon: addUpdateResult.data.result,
                    title: addUpdateResult.data.message,
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }); */
            } else {
                Swal.fire({
                    icon: addUpdateResult.data.result,
                    title: this.modals.addUpdate.title,
                    html: addUpdateResult.data.message,
                    confirmButtonText: 'Tamam',
                    confirmButtonColor: '#1565C0'
                });
            }
        },
        async delete(_id) {
            this.$Progress.start();
            this.search.disabled = true;
            var deleteResult = await axios.delete(process.env.VUE_APP_API_URL + 'admin/campaigns/' + _id);
            this.$Progress.finish();
            this.search.disabled = false;

            if(deleteResult.data.result === 'success') this.getCampaigns();

            Swal.fire({
                icon: deleteResult.data.result,
                title: deleteResult.data.message,
                width: 500,
                toast: true,
                position: 'bottom-start',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        },
        async changeStatus(_id, status) {
            var form = {
                _id: _id,
                status: status
            };

            this.$Progress.start();
            this.search.disabled = true;
            var changeStatusResult = await axios.put(process.env.VUE_APP_API_URL + 'admin/campaigns', form);
            this.$Progress.finish();
            this.search.disabled = false;

            if(changeStatusResult.data.result === 'error') this.getCampaigns();

            Swal.fire({
                icon: changeStatusResult.data.result,
                title: changeStatusResult.data.message,
                width: 500,
                toast: true,
                position: 'bottom-start',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        }
    }
};
</script>

<style lang="scss">
.card {
    .card-header {
        background-color: #1565C0;
        color: white;
    }
}
</style>